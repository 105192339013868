@import 'variables';
@import 'functions';

.before-angular {
  height: 100%;
  width: 100%;
  background: $my7n-loader-background;
  box-sizing: border-box;

  .before-angular-text {
    text-align: center;
  }

  .before-angular-loader {
     align-self: center;

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: pix2rem(64);
      height: pix2rem(64);

      div {
        position: absolute;
        top: pix2rem(27);
        width: pix2rem(11);
        height: pix2rem(11);
        border-radius: 50%;
        background: map-get($map: $my7n-primary-palette, $key: 500);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
    }

    .lds-ellipsis div:nth-child(1) {
      left: pix2rem(6);
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: pix2rem(6);
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: pix2rem(26);
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: pix2rem(45);
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  .before-angular-content {
    height: 100%;
    width: 100%;
    padding-top: pix2rem(160);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    place-content: stretch center;

    @include screen-lt-md() {
      padding-top: pix2rem(120);
    }
  }

  .top-navbar {
    display: block;
    z-index: 100;
    width: 100%;
    position: fixed;
    background: $my7n-dark-blue;
    height: pix2rem(80);
    padding: 0 pix2rem(72);
    box-sizing: border-box;

    @include screen-lt-md() {
      height: pix2rem(60);
      padding: 0 pix2rem(24);
    }
  }

  .logo {
    float: left;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    height: 100%;

    @include screen-lt-md() {
      float: none;
      place-content: center center;
    }
  }

  .logout {
    float: right;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-end;
    align-items: center;
    height: 100%;

    a {
      color: white !important;
    }
  }

  .info-page {
    color: $my7n-header-color;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;

    padding-bottom: pix2rem(60); // top-navbar just to give some space to breath

    .image {
      margin-top: pix2rem(80 + 60);

      img {
        height: pix2rem(290);
        width: auto;
      }

      @include screen-lt-sm() {
        display: none;
      }
    }

    .title {
      text-align: center;
      margin-top: pix2rem(36);
      margin-bottom: pix2rem(36);
      max-width: pix2rem(750);

      @include screen-lt-md() {
        margin-top: pix2rem(84);
        padding-left: pix2rem(24);
        padding-right: pix2rem(24);
      }
    }

    .description {
      max-width: pix2rem(600);
      text-align: center;

      @include screen-lt-md() {
        padding-left: pix2rem(36);
        padding-right: pix2rem(36);
      }
    }

    .title + .description {
      margin-top: 0;
      margin-bottom: pix2rem(24);
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(#{pix2rem(19)}, 0);
  }
}
