body.sort-edit-dragging {
  cursor: grabbing !important;

  .cdk-drag-preview {
      cursor: grabbing;
  }

  .cdk-drag {
      cursor: grabbing;
  }
}
